
    <p-fileUpload
      #fubauto
      [mode]="fileUploadOptions.mode"
      [accept]="fileUploadOptions.accept"
      [maxFileSize]="fileUploadOptions.maxFileSize"
      [auto]="fileUploadOptions.auto"
      [chooseLabel]="fileUploadOptions.chooseLabel"
      [customUpload]="fileUploadOptions.customUpload"
      (uploadHandler)="onUploadImage.emit($event.files[0])"
    >
    </p-fileUpload>
  