
    <img #image
         *ngIf="imageUrl !== null"
         class="image-cropper"
         [src]="imageUrl"
         (load)="cropperLoad()">
    <div *ngIf="imageUrl === null"
         class="img-placeholder">
      <img src="assets/crop-placeholder.svg">
    </div>
  