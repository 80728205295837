
    {{zoomValue() }} %
    <p-slider
      [ngModel]="zoom_slider_value * 100"
      [min]="zoom_slider_min"
      [max]="zoom_slider_max"
      [orientation]="orientation"
      [step]="zoom_slider_step"
      (onChange)="cropperZoom($event)"
    ></p-slider>
  